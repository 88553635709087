import React, { useRef, useState } from "react";
import tw from "twin.macro";

import SignatureCanvas from "react-signature-canvas";

// ========= ICONS =========
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace as clearIcon } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Scribble } from "../../assets/img/Scribble Hand.svg";
import copy from "../../assets/copy";

// import { ReactComponent as KeyboardIcon } from "../../assets";

const SignatureBox = ({ setState }) => {
	const ref = useRef();

	const [hasStarted, setHasStarted] = useState(false);

	return (
		<div css={[tw`relative border-b w-full h-full`]}>
			{!hasStarted && (
				<div
					css={[
						tw`absolute w-full h-full pointer-events-none
						flex justify-center items-center
						text-projectYellow-300`,
					]}
				>
					<span tw="-mb-4 -mr-3">{copy.pledge.sign}</span>
					<Scribble />
				</div>
			)}
			<FontAwesomeIcon
				icon={clearIcon}
				css={tw`absolute top-0 right-0 mt-2 mr-2 text-white`}
				onClick={() => ref.current.clear()}
			/>
			<SignatureCanvas
				ref={ref}
				canvasProps={{ style: tw`w-full h-full` }}
				penColor="white"
				onBegin={() => {
					setHasStarted(true);
				}}
				onEnd={() => {
					const image = ref.current.toDataURL();
					setState(image);
				}}
			/>
		</div>
	);
};

export default SignatureBox;
