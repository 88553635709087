import React, { useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import tw, { css } from "twin.macro";

// ========= UTILS =========
import copy from "../assets/copy";
import {
	logEvent,
	saveImage,
	updatePlayerState,
} from "../libs/auth";

// ========= HOOKS =========
import useLogin from "../hooks/useLogin";

// ========= COMPONENTS =========
import Dropdown from "../components/Elements/Dropdown";
import SEO from "../components/SEO";
import PageBackground from "../components/Elements/PageBackground";
import SignatureBox from "../components/Elements/SignatureBox";
import OptIn from "../components/Elements/OptIn";
import TextButton from "../components/Elements/Buttons/TextButton";

// ========= Assets =========
import penLogo from "../assets/img/brand/PenLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faExclamationCircle as errorIcon,
	faCircle as circleIcon,
} from "@fortawesome/free-solid-svg-icons";

// ========= CUSTOM =========
const Wrapper = tw.div`
	container mx-auto
	text-white text-lg
	lg:mt-32 mt-10 px-5 lg:pr-0
`;

const PledgeInfo = ({ text }) => (
	<div
		css={[
			tw`
				w-full lg:w-1/2
				mb-10 lg:mb-0
			`,
		]}
	>
		<div
			css={[
				tw`
					text-lg md:text-2xl lg:max-w-sm lg:pr-10
				`,
			]}
		>
			<p>{text}</p>
		</div>
	</div>
);

const Pledge = ({
	actions,
	lessons,
	subjects,
	handleSubmit,
	user,
}) => {
	const [action, setAction] = useState();
	const [subject, setSubject] = useState();
	const [lesson, setLesson] = useState();
	const [optIn, setOptIn] = useState(false);

	const [signatureData, setSignatureData] = useState();

	const [failForm, setFailForm] = useState(false);

	// if the signature field hasn't been touched, fail the form
	// if the first dropdown hasn't been filled, fail the form
	// useEffect(() => {
	// 	if (!signatureData || !action) {
	// 		setFailForm(true);
	// 	} else {
	// 		setFailForm(false);
	// 	}
	// }, [signatureData, action]);

	// when the user submits the form
	const onFormSubmit = async (event) => {
		// don't let the form really submit
		event.preventDefault();

		// if the signature field hasn't been touched, fail the form
		// if the first dropdown hasn't been filled, fail the form
		if (!signatureData || !action) {
			setFailForm(true);
			return;
		}
		setFailForm(false);

		// upload the signature image to firebase storage
		const img = await saveImage({
			image: signatureData,
			uid: user.uid,
		});

		// submit the form
		handleSubmit({
			action: action ?? ``,
			subject: subject ?? ``,
			lesson: lesson ?? ``,
			pledgeWall: optIn,
			signature: img,
		});
	};

	// ========= RENDER =========
	return (
		<div
			css={[
				tw`
				flex flex-col items-center
				mb-20 lg:mb-0 w-full lg:w-1/2
			`,
			]}
		>
			<div css={[tw`w-full`]}>
				<p tw="lg:pl-4 md:text-2xl font-bold">
					{copy.pledge.ready}
				</p>

				<form
					onSubmit={onFormSubmit}
					css={css`
						& > div {
							margin: 0.75rem 0;
						}
					`}
				>
					<Dropdown
						options={actions}
						setState={(a) => setAction(a.value)}
					/>
					<Dropdown
						options={subjects}
						setState={(s) => setSubject(s.value)}
					/>
					<Dropdown
						options={lessons}
						setState={(l) => setLesson(l.value)}
					/>
					<div
						css={css`
							height: 150px;
						`}
					>
						<SignatureBox
							setState={(s) => setSignatureData(s)}
						/>
					</div>

					<OptIn
						text={copy.pledge.post}
						setState={(o) => setOptIn(o)}
					/>

					{failForm && (
						<div tw="flex items-center">
							<div
								className="fa-layers fa-fw"
								tw="mr-4"
							>
								<FontAwesomeIcon
									icon={circleIcon}
									size="lg"
									color="white"
								/>
								<FontAwesomeIcon
									icon={errorIcon}
									size="lg"
									color="red"
								/>
							</div>
							<div tw="text-base text-pink">
								{copy.pledge.validate}
							</div>
						</div>
					)}

					<TextButton
						text={copy.pledge.action}
						color="BLUE"
						type="submit"
					/>
				</form>
			</div>
		</div>
	);
};

// ========= MAIN =========
const Index = ({ data }) => {
	const user = useLogin();
	// const [okayToRender, setOkayToRender] = useState(false);

	// useEffect(() => {
	// 	if (!user) return;
	// 	(async () => {
	// 		const ps = await getPlayerState();
	// 		// if (ps?.pledge) navigate(`/quiz`);
	// 		// if (ps?.skippedPledge) navigate(`/quiz`);
	// 		setOkayToRender(true);
	// 	})();
	// }, [user]);

	const handleSubmit = (pledge) => {
		updatePlayerState({ pledge });

		logEvent(`signed_pledge`, { uid: user.uid });

		navigate(`/quiz`);
	};

	const {
		action: actions,
		lesson: lessons,
		subject: subjects,
	} = data.sanitySiteCopy.pledge.pledgeDropdowns;

	// if (!okayToRender) return <PageBackground />;
	return (
		<PageBackground>
			<SEO />
			<Wrapper>
				<div
					css={[
						tw`mb-10 lg:mb-4 flex flex-wrap items-center lg:items-start justify-between`,
					]}
				>
					<div css={tw`lg:w-1/2`}>
						<h1
							css={[
								tw`font-bold uppercase text-lg sm:text-2xl md:text-4xl -mb-2 md:-mb-4`,
							]}
						>
							{copy.labels.expect}
						</h1>
						<h2
							css={[
								tw`italic text-lg sm:text-2xl md:text-4xl text-white mb-0`,
							]}
						>
							{copy.labels.when.ELECTING.text}
						</h2>
					</div>
					<div css={tw`ml-auto`}>
						<img
							alt="PEN America Logo"
							css={[
								tw`
									w-20 lg:hidden
								`,
							]}
							src={penLogo}
						/>
					</div>

					<div
						css={[
							tw`
								text-sm flex justify-between lg:w-1/2 lg:mt-2
							`,
						]}
					>
						<Link
							css={tw`hidden lg:block`}
							to="/settings"
							onClick={() =>
								logEvent(`go_to`, {
									page: `sign_in`,
								})
							}
						>
							{copy.pledge.signIn}
						</Link>
						<Link
							css={tw`hidden lg:block`}
							to="/quiz"
							onClick={() => {
								updatePlayerState({
									skippedPledge: true,
								});
								logEvent(`skipped_pledge`);
							}}
						>
							{copy.pledge.skip}
						</Link>
					</div>
				</div>

				<div css={[tw`flex flex-wrap`]}>
					<PledgeInfo
						text={
							data.sanitySiteCopy.pledge
								?.pledgeInfo ?? ``
						}
					/>
					<Pledge
						actions={actions}
						lessons={lessons}
						subjects={subjects}
						handleSubmit={handleSubmit}
						user={user}
					/>
				</div>

				<div
					css={[
						tw`
						flex justify-between
						text-sm text-white
						mb-5
					`,
					]}
				>
					<Link css={tw`block lg:hidden`} to="/settings">
						{copy.pledge.signIn}
					</Link>
					<Link css={tw`block lg:hidden`} to="/quiz">
						{copy.pledge.skip}
					</Link>
				</div>
			</Wrapper>
		</PageBackground>
	);
};

export default Index;

export const query = graphql`
	query MyQuery {
		sanitySiteCopy {
			pledge {
				pledgeDropdowns {
					action
					lesson
					subject
				}
				pledgeInfo
			}
		}
	}
`;
